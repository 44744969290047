import React, {useEffect, useState} from 'react'
import { PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { useHistory, useParams } from "react-router-dom";
import {callAPI, compareDate, dateInfo} from "../../fuction";


import {
  Paper,
  Typography,
  Container,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {setName} from "../../store/currentPage";
import {useDispatch} from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";

Font.register({ family: 'Roboto', src: '/fonts/roboto-v27-latin-ext_latin-300.ttf', fontStyle: 'normal', fontWeight: '300' });
Font.register({ family: 'Roboto', src: '/fonts/roboto-v27-latin-ext_latin-regular.ttf', fontStyle: 'normal', fontWeight: 'normal' });
Font.register({ family: 'Roboto', src: '/fonts/roboto-v27-latin-ext_latin-500.ttf', fontStyle: 'normal', fontWeight: '500' });

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  row: {
    flexDirection: 'row',
    marginBottom: '5px',
  },
  inline: {
    flexDirection: 'row',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  section: {
    flexGrow: 1
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Roboto',
    marginRight: 20,
  },
  text: {
    fontSize: 12,
    fontWeight: 300,
    fontFamily: 'Roboto',
    marginRight: 20,
  },
  titleSection: {
    fontSize: 14,
    fontWeight: 500,
    borderBottom: '1px solid black',
    fontFamily: 'Roboto',
    marginBottom: '10px',
    marginTop: '10px',
  }
});

const MyDoc = ({order, equipment}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.title}>Data zamówienia</Text>
            <Text style={styles.text}>{dateInfo(order.created_at, false)}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.title}>Klient</Text>
            <Text style={styles.text}>{order.client_name}</Text>
            <Text style={styles.text}>{order.client_address}</Text>
            <Text style={styles.text}>NIP: {order.client_nip}{/*, Regon: {order.client_regon}*/}</Text>
          </View>
        </View>
        <View style={styles.inline}>
          <Text style={styles.title}>Nr zamówienia</Text>
          <Text style={styles.text}>{order.order_number}</Text>
          <Text style={styles.text}>({order.manual_order_number})</Text>
        </View>
        <View style={styles.inline}>
          <Text style={styles.title}>Typ kabiny</Text>
          <Text style={styles.text}>{order.cabin.name}</Text>
        </View>
        <Text style={styles.titleSection}>Dane samochodu</Text>
        <View style={styles.row}>
          <View style={styles.inline}>
            <Text style={styles.title}>Marka samochodu</Text>
            <Text style={styles.text}>{order.brand.name}</Text>
          </View>
          <View style={styles.inline}>
            <Text style={styles.title}>Rok produkcji</Text>
            <Text style={styles.text}>{order.production_year}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.inline}>
            <Text style={styles.title}>Kolor</Text>
            <Text style={styles.text}>{order.color ? order.color.name : ''}</Text>
          </View>
          <View style={styles.inline}>
            <Text style={styles.title}>Nr. lakieru</Text>
            <Text style={styles.text}>{order.color_number}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.inline}>
            <Text style={styles.title}>Kolor samochodu</Text>
            <Text style={styles.text}>{order.color_car}</Text>
          </View>
        <View style={styles.inline}>
          <Text style={styles.title}>Vin</Text>
          <Text style={styles.text}>{order.vin}</Text>
        </View>
        </View>
        {(order.passenger_seat !== null || order.sleep_cabin_type !== null || order.sleep_cabin_bed !== null) && (
          <>
            <Text style={styles.titleSection}>Szczegóły dotyczące wybranej kabiny</Text>
            {order.passenger_seat !== null && (
              <View style={styles.inline}>
                <Text style={styles.title}>Fotel pasażera</Text>
                <Text style={styles.text}>{order.passenger_seat.name}</Text>
              </View>
            )}
            {order.sleep_cabin_type !== null && (
              <View style={styles.inline}>
                <Text style={styles.title}>Kabina sypialna</Text>
                <Text style={styles.text}>{order.sleep_cabin_type.name}</Text>
              </View>
            )}
            {order.sleep_cabin_bed !== null && (
              <View style={styles.inline}>
                <Text style={styles.title}>Kabina sypialna</Text>
                <Text style={styles.text}>{order.sleep_cabin_bed.name}</Text>
              </View>
            )}
          </>
        )}
        {equipment.length > 0 && (
          <View>
            <Text style={styles.titleSection}>Wyposażenie dodatkowe dla wybranej kabiny</Text>
            {equipment.map(el => (
              <View style={styles.inline}>
                <Text style={styles.title}>{el.additional_equipment.name}{compareDate('2025-01-01T00:00:00', order.created_at) > 0 ? ' - ' + el.additional_equipment.price + 'zł' : ''}{el.additional_equipment.comment ? ', ' + el.additional_equipment.comment : ''}</Text>
                <Text style={styles.text}>{el.comment}</Text>
              </View>
            ))}
          </View>
        )}
        <Text style={styles.titleSection}>Informacje o realizacji zamówienia</Text>
        <View style={styles.row}>
          <View style={styles.inline}>
            <Text style={styles.title}>Miejsce montażu</Text>
            <Text style={styles.text}>{order.place_assembly}</Text>
          </View>
          <View style={styles.inline}>
            <Text style={styles.title}>Termin realizacji zamówienia</Text>
            <Text style={styles.text}>{dateInfo(order.order_completion_time, false)}</Text>
          </View>
        </View>
        {/*<Text style={styles.titleSection}>Etapy realizacji zamówienia</Text>
        <View style={styles.row}>
          <View style={styles.inline}>
            <Text style={styles.title}>Wylaminowana</Text>
            <Text style={styles.text}>{dateInfo(order.laminated, false)}</Text>
          </View>
          <View style={styles.inline}>
            <Text style={styles.title}>Wykostkowana</Text>
            <Text style={styles.text}>{dateInfo(order.outcropped, false)}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.inline}>
            <Text style={styles.title}>Wytapicerowana</Text>
            <Text style={styles.text}>{dateInfo(order.upholstered, false)}</Text>
          </View>
          <View style={styles.inline}>
            <Text style={styles.title}>Lakierowanie</Text>
            <Text style={styles.text}>{dateInfo(order.varnishing, false)}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.inline}>
            <Text style={styles.title}>Gotowa do montażu</Text>
            <Text style={styles.text}>{dateInfo(order.ready_to_assembly, false)}</Text>
          </View>
          <View style={styles.inline}>
            <Text style={styles.title}>Montaż</Text>
            <Text style={styles.text}>{dateInfo(order.assembly, false)}</Text>
          </View>
        </View>*/}

        <Text style={styles.titleSection}>Dodatkowe informacje</Text>
        <View style={styles.inline}>
          <Text style={styles.title}>Lączna kwota zamówienia</Text>
          <Text style={styles.text}>{order.total_price}</Text>
        </View>
        <View style={styles.inline}>
          <Text style={styles.title}>Uwagi do zamówienia</Text>
          <Text style={styles.text}>{order.comment}</Text>
        </View>
      </Page>
    </Document>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    marginBottom: theme.spacing(8)
  },
  toolbarSecondary: {
    justifyContent: 'flex-start',
    overflowX: 'auto',
  },
  toolbarSecondaryButtons: {
    marginLeft: theme.spacing(4)
  }
}));

const PdfGenerator = () => {
  const history = useHistory();
  let params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch()

  const [order, setOrder] = useState({})
  const [equipment, setEquipment] = useState({})

  useEffect(() => {
    dispatch(setName('Wydrukuj zamówienie'))
  }, [])

  useEffect(() => {
    callAPI({
      url: `/api/v1/orders/${params.id}`,
      method: 'GET'
    })
      .then(res => {
        setOrder(res.data)
      })
    callAPI({
      url: `/api/v1/orders/${params.id}/equipments`,
      method: 'GET'
    })
      .then(res => {
        setEquipment(res.data)
      })
  }, [params.id])

  return (
    <>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        <Typography variant="body2">Wróć do:</Typography>
        <Button className={classes.toolbarSecondaryButtons} color="primary" onClick={() => history.push("/")}>Lista zamówień</Button>
        <Button className={classes.toolbarSecondaryButtons} color="primary" onClick={() => history.push(`/detail/${params.id}`, {order, equipment})}>Szczegóły zamówienia</Button>
      </Toolbar>
      <Container>
        {order.uuid != undefined && (
          <>
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h1">
              Zapisz i wydrukuj zamówienie nr. {order.order_number}
            </Typography>
            <Typography variant="body1" className={classes.title}>
              Poczekaj, aż plik się wyrenderuje. Jeśli przycisk będzie nieaktywny odśwież stronę.
            </Typography>
            <PDFDownloadLink document={<MyDoc order={order} equipment={equipment} />} fileName={`zamowienie-${order.order_number}.pdf`}>
              {({ blob, url, loading, error }) =>
                loading ? 'Generuję dokument...' : 'Pobierz teraz!'
              }
            </PDFDownloadLink>
          </Paper>
            {/*<PDFViewer width={1200} height={1600}>*/}
            {/*  <MyDoc order={order} equipment={equipment} />*/}
            {/*</PDFViewer>*/}

          </>
        )}
      </Container>
    </>
  )
}


export default PdfGenerator

import React, {useEffect, useState} from 'react'

import {
  Paper,
  Typography,
  Container,
  Button, TextField, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import {useHistory, useParams, Link, useLocation} from "react-router-dom";
import {callAPI, dateInfo, status, statusList, compareDate} from "../../fuction";
import {useDispatch} from "react-redux";
import {setName} from "../../store/currentPage";
import Toolbar from "@material-ui/core/Toolbar";
import {setNoticeError, setNoticeSuccess} from "../../store/notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  formContainer: {
    [theme.breakpoints.up('d')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 3fr',
      gap: theme.spacing(3),
    }
  },
  addEquipmentList: {
    [theme.breakpoints.up('d')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(3),
    }
  },
  subtitle: {
    marginTop: theme.spacing(2),
    borderBottom: '1px solid black',
    marginBottom: 0,
    fontWeight: 'bold',
  },
  field: {
    marginRight: 0,
    minWidth: 300,
    maxWidth: 400,
    marginBottom: 0,
    width: '100%',
},
  toolbarSecondary: {
    justifyContent: 'flex-start',
    overflowX: 'auto',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  form: {
    display: 'block',
    width: '100%',
    marginTop: 0,
  },
  removeBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  buttonSubmit: {
    marginTop: theme.spacing(2)
  },
  formEdit: {
    padding: theme.spacing(2),
    paddingTop: 0,
    marginTop: theme.spacing(2),
    border: '1px dotted black',
  }
}));

function DetailOrder() {
  const history = useHistory();
  const classes = useStyles();
  let params = useParams();
  const location = useLocation()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /*const modalbody = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p>
    </div>
  );*/

  const [order, setOrder] = useState((location.state !== undefined && location.state.order !== undefined && location.state.order) ? location.state.order : {})
  const [equipment, setEquipment] = useState(location.state !== undefined && location.state.equipment !== undefined && location.state.equipment ? location.state.equipment : [])

  useEffect(() => {
    dispatch(setName('Szczegóły zamówienia'))
  }, [])

  useEffect(() => {
    callAPI({
      url: `/api/v1/orders/${params.id}`,
      method: 'GET'
    })
      .then(res => {
        setOrder(res.data)
      })
    callAPI({
      url: `/api/v1/orders/${params.id}/equipments`,
      method: 'GET'
    })
      .then(res => {
        setEquipment(res.data)
      })
  }, [params.id])

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData(e.target)
    const equpment = data.getAll('additional_equipment_id')
    equpment.map(el => {
      data.delete(`comment-${el}`)
    })
    data.delete('additional_equipment_id')
    data.delete('cabin_id')
    data.delete('brand_id')
    data.delete('color_id')

    const dataFromOrder = {
      client_name: order.client_name,
      client_address: order.client_address,
      client_nip: order.client_nip,
      client_regon: order.client_regon,
      status: order.status,
      production_year: order.production_year,
      color_number: order.color_number,
      color_car: order.color_car,
      vin: order.vin,
      place_assembly: order.place_assembly,
      order_completion_time: order.order_completion_time ? order.order_completion_time.slice(0, 10) : '',
      laminated: order.laminated,
      outcropped: order.outcropped,
      upholstered: order.upholstered,
      varnishing: order.varnishing,
      ready_to_assembly: order.ready_to_assembly,
      assembly: order.assembly,
      total_price: order.total_price,
      comment: order.comment,
      passenger_seats_id: order.passenger_seats_id,
      sleep_cabin_beds_id: order.sleep_cabin_beds_id,
      sleep_cabin_types_id: order.sleep_cabin_types_id,
      manual_order_number: order.manual_order_number
    }

    const dataFormForm = Object.fromEntries(data.entries())

    const jsonData = {...dataFromOrder, ...dataFormForm}

    if (jsonData.passenger_seats_id) {
      jsonData.passenger_seats_id = Number(order.passenger_seat.uuid)
    } else {
      jsonData.passenger_seats_id = null
    }
    if (jsonData.sleep_cabin_beds_id) {
      jsonData.sleep_cabin_beds_id = Number(order.sleep_cabin_bed.uuid)
    } else {
      jsonData.sleep_cabin_beds_id = null
    }
    if (jsonData.sleep_cabin_types_id) {
      jsonData.sleep_cabin_types_id = Number(order.sleep_cabin_type.uuid)
    } else {
      jsonData.sleep_cabin_types_id = null
    }
    if (jsonData.status) {
      jsonData.status = Number(jsonData.status)
    } else {
      jsonData.status = null
    }

    const update = () => {
      callAPI({
        url: `/api/v1/orders/${params.id}`,
        method: 'PATCH',
        data: jsonData
      }).then(res => {
        dispatch(setNoticeSuccess('Zamówienie zmienione poprawnie'))
        history.push(`/detail/${res.data.uuid}`)
      })
    }

    update()

    return false
  }

  const handleDeleteOrder = () => {
      callAPI({
        url: `/api/v1/orders/${order.uuid}`,
        method: 'DELETE'
      })
        .then(() => {
          dispatch(setNoticeSuccess('Zamówienie zostało usunięte'))
          history.push(`/`)
        }).catch(() => {
        dispatch(setNoticeError('Błąd usuwania. Spróbuj jescze raz.'))
      })
  }

  const ManualNumber = () => {
    if (order.manual_order_number !== null) {
      return (<strong>({order.manual_order_number})</strong>)
    }
    return ''
  }

  return (
    <>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        <Typography variant="body2">Wróć do:</Typography>
        <Button color="primary" onClick={() => history.push("/")}>Lista zamówień</Button>
        <Typography variant="body2">|</Typography>
        <Typography variant="body2">Zarządzaj zamówieniem:</Typography>
        <Link to={`/generate-pdf/${order.uuid}`} component={Button}>Zapisz i wydrukuj zamówienie</Link>
        <Button color="primary" onClick={() => history.push(`/edit/${order.uuid}`, {order, equipment})}>Edytuj zamówienie</Button>
      </Toolbar>
      <Container maxWidth={false}>
        {order.uuid != undefined && (
          <>
            <Paper className={classes.paper}>
              <Typography variant="h4" component="h1">
                Zamówienie nr. {order.order_number} <ManualNumber />
              </Typography>
              <Typography variant="body1" className={classes.title}>
                Data zamówienia {dateInfo(order.created_at)}
              </Typography>
              <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                <div className={classes.formContainer}>
                  <div>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Dane klienta
                    </Typography>
                    <div>
                      <Typography variant="body1">{order.client_name}</Typography>
                      <Typography variant="body2">{order.client_address}</Typography>
                      <Typography variant="body1">NIP: {order.client_nip}</Typography>
                      {/*<Typography variant="body1">REGON: {order.client_regon}</Typography>*/}
                    </div>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Informacje o realizacji zamówienia
                    </Typography>
                    <div>
                      {order.place_assembly !== null && (
                          <TextField
                              InputProps={{
                                readOnly: true
                              }}
                              name="place_assembly" value={order.place_assembly} label="Miejsce montażu"
                              className={classes.field}/>
                      )}
                      {order.order_completion_time !== null && (
                          <TextField name="order_completion_time"
                                     value={order.order_completion_time ? order.order_completion_time.substring(0, 10) : ''}
                                     inputProps={{
                                       readOnly: true,
                                       type: 'date'
                                     }}
                                     label="Termin realizacji zamówienia" className={classes.field}/>
                      )}
                    </div>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Dodatkowe informacje
                    </Typography>
                    <div>
                      {order.total_price !== null && (
                          <TextField
                              inputProps={{
                                readOnly: true
                              }}
                              name="total_price" value={order.total_price} label="Lączna kwota zamówienia"
                              className={classes.field}/>
                      )}
                      {order.comment !== null && (
                          <TextField
                              inputProps={{
                                readOnly: true
                              }}
                              multiline
                              maxRows={5}
                              name="comment" value={order.comment} label="Uwagi do zamówienia" className={classes.field}/>

                      )}

                    </div>
                    <div className={classes.formEdit}>
                      <Typography variant="subtitle1" className={classes.subtitle}>
                        Status zamówienia
                      </Typography>
                      <div>
                        <FormControl>
                          <InputLabel id="status">Status</InputLabel>
                          <Select name="status" defaultValue={order.status} labelId="status" className={classes.field}>
                            {statusList.map(el => (
                                <MenuItem key={el} value={el}>{status(el)}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <Button type="submit" className={classes.buttonSubmit} variant="contained">Zapisz zmiany</Button>
                    </div>
                  </div>
                  <div>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Dane samochodu
                    </Typography>
                    <div>
                      {order.brand && order.brand.name !== undefined && (
                          <>
                            <Typography variant="body1">
                              <strong>Marka samochodu:</strong> {order.brand.name}
                            </Typography>
                          </>
                      )}
                      {order.production_year !== '' && (
                          <Typography variant="body1">
                            <strong>Rok produkcji:</strong> {order.production_year}
                          </Typography>
                      )}
                      {order.color && order.color.name !== undefined && (
                          <Typography variant="body1">
                            <strong>Kolor:</strong> {order.color.name}
                          </Typography>
                      )}
                      {order.color_number !== '' && (
                          <Typography variant="body1">
                            <strong>Numer lakieru:</strong> {order.color_number}
                          </Typography>
                      )}
                      {order.color_car !== '' && (
                          <Typography variant="body1">
                            <strong>Kolor samochodu:</strong> {order.color_car}
                          </Typography>
                      )}
                      {order.vin !== '' && (
                          <Typography variant="body1">
                            <strong>VIN:</strong> {order.vin}
                          </Typography>
                      )}
                    </div>
                    {order.cabin.name !== undefined && (
                        <>
                          <Typography variant="subtitle1" className={classes.subtitle}>
                            Typ kabiny
                          </Typography>
                          <Typography variant="subtitle2">
                            {order.cabin.name}
                          </Typography>
                        </>
                    )}
                    {(order.passenger_seat !== null || order.sleep_cabin_type !== null || order.sleep_cabin_bed !== null) && (
                        <>
                          <Typography variant="subtitle1" className={classes.subtitle}>
                            Szczegóły dotyczące wybranej kabiny
                          </Typography>
                          <div>
                            {order.passenger_seat !== null && (
                                <TextField
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    name="passenger_seats_id" value={order.passenger_seat.name} label="Fotel pasażera"
                                    className={classes.field}/>
                            )}
                            {order.sleep_cabin_type !== null && (
                                <TextField
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    name="sleep_cabin_types_id" value={order.sleep_cabin_type.name}
                                    label="Kabina sypialna" className={classes.field}/>
                            )}
                            {order.sleep_cabin_bed !== null && (
                                <TextField
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    name="sleep_cabin_beds_id" value={order.sleep_cabin_bed.name} label="Kabina sypialna"
                                    className={classes.field}/>
                            )}
                          </div>
                        </>
                    )}
                  </div>
                  <div>
                    {equipment.length > 0 && (
                        <>
                          <Typography variant="subtitle1" className={classes.subtitle}>
                            Wyposażenie dodatkowe dla wybranej kabiny
                          </Typography>
                          <div className={classes.addEquipmentList}>
                            {equipment.map(el => (
                                <div key={el.uuid}>
                                  <FormControlLabel
                                      key={el.uuid}
                                      control={<Checkbox name="additional_equipment_id" value={el.uuid} checked readOnly/>}
                                      label={
                                        `${el.additional_equipment.name}${compareDate('2025-01-01T00:00:00', order.created_at) > 0 ? ' - ' + el.additional_equipment.price + 'zł' : ''}${el.additional_equipment.comment ? ', ' + el.additional_equipment.comment : ''}`
                                      }/>
                                  {el.comment !== null && (
                                      <TextField
                                          InputProps={{
                                            readOnly: true
                                          }}
                                          name={`comment-${el.uuid}`}
                                          value={el.comment}
                                          className={classes.field}
                                      />
                                  )}
                                </div>
                            ))}
                          </div>
                        </>
                    )}
                  </div>
                </div>






                  {/*<Typography variant="subtitle1" className={classes.subtitle}>
                Etapy realizacji zamówienia
              </Typography>
              <div>
                {order.laminated !== null ? (
                  <TextField
                    value={order.laminated.substring(0,10)}
                    name="laminated"
                    inputProps={{
                      readOnly: true,
                      type: 'date'
                    }}
                    label="Wylaminowana" className={classes.field} />
                ) :(
                  <TextField name="laminated" defaultValue={order.laminated ? order.laminated.substring(0,10) : ''} inputProps={{
                    type: 'date'
                  }}
                             InputLabelProps={{
                               shrink: true,
                             }}
                             label="Wylaminowana" className={classes.field} />
                )}
                {order.outcropped !== null ? (
                  <TextField name="outcropped"
                             value={order.outcropped.substring(0,10)}
                             inputProps={{
                               readOnly: true,
                               type: 'date'
                             }}
                             label="Wykostkowana" className={classes.field} />
                ) : (
                  <TextField name="outcropped" defaultValue={order.outcropped ? order.outcropped.substring(0,10) : ''}
                             inputProps={{
                               type: 'date'
                             }}
                             InputLabelProps={{
                               shrink: true,
                             }}
                             label="Wykostkowana" className={classes.field} />
                )}
                {order.upholstered !== null ? (
                  <TextField name="upholstered"
                             value={order.upholstered.substring(0,10)}
                             inputProps={{
                               readOnly: true,
                               type: 'date'
                             }}
                             label="Wytapicerowana" className={classes.field} />
                ) : (
                  <TextField name="upholstered" defaultValue={order.upholstered ? order.upholstered.substring(0,10) : ''}
                             inputProps={{
                               type: 'date'
                             }}
                             InputLabelProps={{
                               shrink: true,
                             }}
                             label="Wytapicerowana" className={classes.field} />
                )}
                {order.varnishing !== null ? (
                  <TextField name="varnishing"
                             value={order.varnishing.substring(0,10)}
                             inputProps={{
                               readOnly: true,
                               type: 'date'
                             }}
                             label="Lakierowanie" className={classes.field} />
                ) : (
                  <TextField name="varnishing" defaultValue={order.varnishing ? order.varnishing.substring(0,10) : ''}
                             inputProps={{
                               type: 'date'
                             }}
                             InputLabelProps={{
                               shrink: true,
                             }}
                             label="Lakierowanie" className={classes.field} />
                )}
                {order.ready_to_assembly !== null ? (
                  <TextField name="ready_to_assembly"
                             value={order.ready_to_assembly.substring(0,10)}
                             inputProps={{
                               readOnly: true,
                               type: 'date'
                             }}
                             label="Gotowa do montażu" className={classes.field} />
                ) : (
                  <TextField name="ready_to_assembly" defaultValue={order.ready_to_assembly ? order.ready_to_assembly.substring(0,10) : ''}
                             inputProps={{
                               type: 'date'
                             }}
                             InputLabelProps={{
                               shrink: true,
                             }}
                             label="Gotowa do montażu" className={classes.field} />
                )}
                {order.assembly !== null ? (
                  <TextField name="assembly"
                             value={order.assembly.substring(0,10)}
                             inputProps={{
                               readOnly: true,
                               type: 'date'
                             }}
                             label="Montaż" className={classes.field} />
                ) : (
                  <TextField name="assembly" defaultValue={order.assembly ? order.assembly.substring(0,10) : ''}
                             inputProps={{
                               type: 'date'
                             }}
                             InputLabelProps={{
                               shrink: true,
                             }}
                             label="Montaż" className={classes.field} />
                )}

              </div>*/}
              </form>
              <div className={classes.removeBtn}>
                <Button type="button" onClick={handleOpen} color="secondary" variant="contained">Usuń zamówienie</Button>
              </div>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Czy na pewno chcesz usunąć zamówienia?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Ta czynność jest nieodwracalna. Potwierdzając usunięcie utracisz wszystkie dane z tego zamówienia.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteOrder} color="secondary">
                    Usuń zamówienie
                  </Button>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    Anuluj
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </>
        )}
      </Container>
    </>
  )
}

export default DetailOrder

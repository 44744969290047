import React, {useMemo} from 'react';
import './App.css';
import Auth from "./Auth";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useSelector } from 'react-redux'
import { useEffect } from "react";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';


function MyApp() {
  const { enqueueSnackbar } = useSnackbar();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: '#eaeaea',
            paper: '#fff'
          }
        },
          breakpoints: {
              values: {
                  xs: 0,
                  sm: 600,
                  md: 960,
                  lg: 1280,
                  xl: 1920,
                  d: 1600,
              },
          },
      }),
    [],
  );

  const notistackData = useSelector((state) => state.notistack)

  useEffect(() => {
    if (notistackData !== undefined && notistackData.text !== undefined && notistackData.text !== '') {
      enqueueSnackbar(notistackData.text, {variant: notistackData.type});
    }
  }, [notistackData, enqueueSnackbar])

  return (
    <ThemeProvider theme={theme}>
      <Auth />
    </ThemeProvider>
  );
}

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyApp />
    </SnackbarProvider>
  );
}

export default App;

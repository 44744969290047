import React, {useState, useEffect} from 'react'
import {
  Paper,
  Typography,
  Container,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem, FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import {setNoticeError, setNoticeSuccess} from "../../store/notistack";
import { useHistory } from "react-router-dom";
import {callAPI} from "../../fuction";
import {setName} from "../../store/currentPage";
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  form: {
    marginTop: 0,
  },
  formContainer: {
    [theme.breakpoints.up('d')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 3fr',
      gap: theme.spacing(3),
    }
  },
  addEquipmentList: {
    [theme.breakpoints.up('d')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(3),
    }
  },
  subtitle: {
    marginTop: theme.spacing(2),
    borderBottom: '1px solid black',
    marginBottom: 0,
    fontWeight: 'bold',
  },
  field: {
    marginRight: 0,
    minWidth: 300,
    maxWidth: 400,
    marginBottom: 0,
    width: '100%',
  },
  toolbarSecondary: {
    justifyContent: 'flex-start',
    overflowX: 'auto',
  },
  toolbarSecondaryButtons: {
    marginLeft: theme.spacing(4)
  },
  buttonSubmit: {
    marginTop: theme.spacing(2)
  }
}));

function NewOrder() {
  const history = useHistory();
  const dispatch = useDispatch()
  const classes = useStyles();
  const cabinTypes = useSelector(state => state.dictionary.cabins)
  const brands = useSelector(state => state.dictionary.brands)
  const colors = useSelector(state => state.dictionary.colors)
  const passengerSeats = useSelector(state => state.dictionary.passengerSeats)
  const sleepCabinTypes = useSelector(state => state.dictionary.sleepCabinTypes)
  const sleepCabinBeds = useSelector(state => state.dictionary.sleepCabinBeds)
  const additionalEquipment = useSelector(state => state.dictionary.additionalEquipment)

  const [cabin, setCabin] = useState(0)
  const [equipment, setEquipment] = useState([])

  useEffect(() => {
    dispatch(setName('Nowe zamówienie'))
  }, [])

  const handleChangeEqupment = (e, el) => {
    const checked = e.target.form.querySelectorAll('[name="additional_equipment_id"]:checked')
    setEquipment([])
    checked.forEach(input => {
      setEquipment(state => [...state, input.value])
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData(e.target)

    const equpment = data.getAll('additional_equipment_id')
    let dataEqupment = equpment.map(el => {
      return ({
        additional_equipment_id: Number(el),
        comment: data.get(`comment-${el}`)
      })
    })
    data.delete('additional_equipment_id')
    equipment.forEach(el => {
      data.delete(`comment-${el}`)
    })
    const jsonData = Object.fromEntries(data.entries())
    if (jsonData.brand_id) {
      jsonData.brand_id = Number(jsonData.brand_id)
    } else {
      jsonData.brand_id = null
    }
    if (jsonData.cabin_id) {
      jsonData.cabin_id = Number(jsonData.cabin_id)
    } else {
      jsonData.cabin_id = null
    }
    if (jsonData.color_id) {
      jsonData.color_id = Number(jsonData.color_id)
    } else {
      jsonData.color_id = null
    }
    if (jsonData.passenger_seats_id) {
      jsonData.passenger_seats_id = Number(jsonData.passenger_seats_id)
    } else {
      jsonData.passenger_seats_id = null
    }
    if (jsonData.sleep_cabin_beds_id) {
      jsonData.sleep_cabin_beds_id = Number(jsonData.sleep_cabin_beds_id)
    } else {
      jsonData.sleep_cabin_beds_id = null
    }
    if (jsonData.sleep_cabin_types_id) {
      jsonData.sleep_cabin_types_id = Number(jsonData.sleep_cabin_types_id)
    } else {
      jsonData.sleep_cabin_types_id = null
    }


    callAPI({
      url: '/api/v1/orders',
      method: 'POST',
      data: jsonData
    }).then(res => {
      let counter = 0
      const countermax = dataEqupment.length
      if (res.data.uuid) {
        dataEqupment.forEach(de => {
          callAPI({
            url: '/api/v1/orders-equipment',
            method: 'POST',
            data: {
                ...de,
                order_id: res.data.uuid,
              price: ''
            }
          })
            .then(res => {
              counter += 1
              if (countermax === counter) {
                dispatch(setNoticeSuccess('Zamówienie dodane poprawnie'))
                history.push("/")
              }
            })
        })
        if (countermax == 0) {
          dispatch(setNoticeSuccess('Zamówienie dodane poprawnie'))
          history.push("/")
        }
      }
    })

    return false
  }
  return (
    <>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        <Typography variant="body2">Wróć do:</Typography>
        <Button className={classes.toolbarSecondaryButtons} color="primary" onClick={() => history.push("/")}>Lista zamówień</Button>
      </Toolbar>
      <Container maxWidth={false}>
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h1" className={classes.title} >
            Nowe zamówienie
          </Typography>
          <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
            <div className={classes.formContainer}>
              <div>
                <Typography variant="body2" >
                  Data i numer zamówienia ustawiane automatycznie
                </Typography>
                <div>
                  <TextField name="manual_order_number" label="Dodatkowy numer zamówienia" className={classes.field} />
                </div>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Dane klienta
                </Typography>
                <div>
                  <TextField name="client_name" required label="Nazwa klienta" className={classes.field} />
                  <TextField name="client_address" label="Numer telefonu" className={classes.field} />
                  <TextField name="client_nip" label="NIP klienta" className={classes.field} />
                  {/*<TextField name="client_regon" label="Regon klienta" className={classes.field} />*/}
                </div>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Informacje o realizacji zamówienia
                </Typography>
                <div>
                  <TextField name="place_assembly" label="Miejsce montażu" className={classes.field} />
                  <TextField name="order_completion_time"
                             inputProps={{
                               type: 'date'
                             }}
                             InputLabelProps={{
                               shrink: true,
                             }}
                             label="Termin realizacji zamówienia" className={classes.field} />
                </div>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Dodatkowe informacje
                </Typography>
                <div>
                  <TextField name="total_price" label="Lączna kwota zamówienia" className={classes.field} />
                  <TextField name="comment" label="Uwagi do zamówienia" className={classes.field} />

                </div>
              </div>
              <div>
                {cabinTypes !== undefined && cabinTypes.length > 0 && (
                    <>
                      <Typography variant="subtitle1" className={classes.subtitle}>
                        Typ kabiny
                      </Typography>
                      <div>
                        <FormControl>
                          <InputLabel required id="cabins-types">Wybierz typ kabiny</InputLabel>
                          <Select required name="cabin_id" labelId="cabins-types" className={classes.field} onChange={(e => setCabin(e.target.value))}>
                            {cabinTypes.map(el => (
                                <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </>
                )}
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Dane samochodu
                </Typography>
                <div>
                  {brands !== undefined && brands.length > 0 && (
                      <>
                        <FormControl>
                          <InputLabel required id="cabins-types">Marka samochodu</InputLabel>
                          <Select required name="brand_id" labelId="cabins-types" className={classes.field}>
                            {brands.map(el => (
                                <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                  )}
                  <TextField name="production_year" label="Rok produkcji" className={classes.field} />
                  {colors !== undefined && colors.length > 0 && (
                      <>
                        <FormControl>
                          <InputLabel id="cabins-types">Kolor</InputLabel>
                          <Select name="color_id" labelId="cabins-types" className={classes.field}>
                            {colors.map(el => (
                                <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                  )}
                  <TextField name="color_number" label="Numer lakieru" className={classes.field} />
                  <TextField name="color_car" label="Kolor samochodu" className={classes.field} />
                  <TextField name="vin" label="VIN" className={classes.field} />
                </div>
                {(passengerSeats.find(el => el.cabin.uuid === cabin) || sleepCabinTypes.find(el => el.cabin.uuid === cabin) || sleepCabinBeds.find(el => el.cabin.uuid === cabin)) && (
                    <>
                      <Typography variant="subtitle1" className={classes.subtitle}>
                        Szczegóły dotyczące wybranej kabiny
                      </Typography>
                      <div>
                        {passengerSeats.find(el => el.cabin.uuid === cabin) && (
                            <>
                              <FormControl>
                                <InputLabel id="cabins-types">Fotel pasażera</InputLabel>
                                <Select name="passenger_seats_id" labelId="cabins-types" className={classes.field}>
                                  {passengerSeats.filter(el => el.cabin.uuid === cabin).map(el => (
                                      <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                        )}
                        {sleepCabinTypes.find(el => el.cabin.uuid === cabin) && (
                            <>
                              <FormControl>
                                <InputLabel id="cabins-types">Kabina sypialna</InputLabel>
                                <Select name="sleep_cabin_types_id" labelId="cabins-types" className={classes.field}>
                                  {sleepCabinTypes.filter(el => el.cabin.uuid === cabin).map(el => (
                                      <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                        )}
                        {sleepCabinBeds.find(el => el.cabin.uuid === cabin) && (
                            <>
                              <FormControl>
                                <InputLabel id="cabins-types">Kabina sypialna</InputLabel>
                                <Select name="sleep_cabin_beds_id" labelId="cabins-types" className={classes.field}>
                                  {sleepCabinBeds.filter(el => el.cabin.uuid === cabin).map(el => (
                                      <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                        )}
                      </div>
                    </>
                )}
              </div>
              <div>
                {additionalEquipment.find(el => el.cabin.uuid === cabin) && (
                    <>
                      <Typography variant="subtitle1" className={classes.subtitle}>
                        Wyposażenie dodatkowe dla wybranej kabiny
                      </Typography>
                      <FormGroup className={classes.addEquipmentList}>
                        {additionalEquipment.filter(el => el.cabin.uuid === cabin).map(el => (
                            <div key={el.uuid}>
                              <FormControlLabel
                                  key={el.uuid}
                                  control={<Checkbox name="additional_equipment_id" value={el.uuid} />}
                                  onChange={(e) => handleChangeEqupment(e, el)}
                                  label={
                                    `${el.name} ${el.comment ? ' - ' + el.comment : ''}`
                                  } />
                              {equipment.find(e => e === String(el.uuid)) && (
                                  <TextField name={`comment-${el.uuid}`} label="Dodatkowe informacje" className={classes.field} />
                              )}
                            </div>
                        ))}
                      </FormGroup>
                    </>
                )}

              </div>
            </div>






            {/*<Typography variant="subtitle1" className={classes.subtitle}>
              Etapy realizacji zamówienia
            </Typography>
            <div>
              <TextField name="laminated" inputProps={{
                type: 'date'
              }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Wylaminowana" className={classes.field} />
              <TextField name="outcropped"
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Wykostkowana" className={classes.field} />
              <TextField name="upholstered"
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Wytapicerowana" className={classes.field} />
              <TextField name="varnishing"
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Lakierowanie" className={classes.field} />
              <TextField name="ready_to_assembly"
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Gotowa do montażu" className={classes.field} />
              <TextField name="assembly"
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Montaż" className={classes.field} />
               </div>*/}

            <Button type="submit" variant="contained" className={classes.buttonSubmit}>Dodaj zamówienie</Button>

          </form>
        </Paper>
      </Container>
    </>
  )
}

export default NewOrder
